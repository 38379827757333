import { createSelector } from 'reselect'

import { ApiFeature } from '@app/constants/ApiTypes/entities'

import { assertApiActionResponse } from '@app/utils/performFetchData'

import { ActionRequiredError } from '@app/packages/ActionRequiredError/ActionRequiredError'
import { intoResult, unwrapResult } from '@app/packages/Result/Result'

import { getFeatures } from '@app/store/actions/api/features'
import { createReduxSlice } from '@app/store/redux_slice'
import { profileUserResultSelector } from '@app/store/selectors/profile'

const featuresSlice = createReduxSlice<{ hash: string; list: ApiFeature[] }>('features')

export const featuresResultSelector = createSelector([featuresSlice.selector, profileUserResultSelector], (slice, userResult) =>
  intoResult(() => {
    const user = unwrapResult(userResult)
    if (!user) return []
    const hash = [user.id, user.account_type, user.account_type === 'sitter' ? user.has_training_request : 'null'].join(':')
    if (!slice || slice.hash !== hash)
      throw ActionRequiredError.create('Features must be fetched', hash, async dispatch => {
        const response = await dispatch(getFeatures()).then(assertApiActionResponse(dispatch, 'Features fetch failed'))
        dispatch(featuresSlice.set({ hash, list: response.data }))
      })
    return slice.list
  })
)

export const createFeatureEnabledSelector = (name: string) =>
  createSelector([featuresResultSelector], featuresResult =>
    intoResult(() => {
      const features = unwrapResult(featuresResult)
      return !!features.find(f => f.attributes.name === name && f.attributes.enabled)
    })
  )

export const stripeEnabledSelector = createFeatureEnabledSelector('stripe')
export const savedAnnouncementsSearchEnabledSelector = createFeatureEnabledSelector('saved_announcements_searches')
export const onboardingV2EnabledResultSelector = createFeatureEnabledSelector('candidate_onboarding_v2')
