import { createSelector } from 'reselect'

import { StoreState } from '@app/store/store'

export const schoolModelsSelector = (state: StoreState) => state.schools.models
export const schoolsSelector = createSelector([schoolModelsSelector], schools =>
  Object.values(schools).map(school => ({
    id: school.id,
    ...school.attributes,
    region_id: school.relationships.region.data?.id,
  }))
)
